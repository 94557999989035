


















































import {Component, Vue} from 'vue-property-decorator';
import LoginRequest from "@/models/request/LoginRequest";
import {sessionService} from "@/services";
import {VForm} from "@/utils/form-utils";
import {Message} from "@/models/Message";

@Component({
  components: {},
})
export default class LoginView extends Vue {

  valid: boolean = false;
  serverError: string = null;
  loginRequest: LoginRequest = new LoginRequest();
  hideForm: boolean = false;

  $refs!: {
    form: VForm
  }

  passwordRules: Array<Function> = [
    (v) => {
      return !!v || 'Hasło jest wymagane'
    }
  ];

  usernameRules: Array<Function> = [
    (v) => {
      return !!v || 'Nazwa użytkownika jest wymagana'
    }
  ];

  async login(): Promise<void> {

    this.serverError = null;

    if(this.$refs.form.validate()){

      let message: Message = await sessionService.login(this.loginRequest);

      if(!message.message){
        this.hideForm = true;

        if(await sessionService.isSystemBlockedForCurrentUser()){
          await this.$router.push({ path: '/lock' })
        }else{
          await this.$router.push({ path: '/' })
        }

      }else{
        this.serverError = message.message;
      }

    }

  }

}

